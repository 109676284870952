import request from '../request'

export function fetchList(data) {
    return request({
        url: '/basic-service/log/list',
        method: 'POST',
        data
    })
}

export function fetchModuleList() {
    return request({
        url: '/basic-service/log/stat',
        method: 'post',
        data: [
            "app_module"
        ]
    })
}