<template>
  <Card title="系统日志">
    <a-form-model layout="inline" @keyup.enter.native="query">
      <a-form-model-item>
        <a-select
          v-model="form.appModule"
          placeholder="模块"
          style="width: 150px"
        >
          <a-select-option v-for="item in moduleList" :key="item" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-select
          v-model="form.logType"
          placeholder="类型"
          style="width: 150px"
        >
          <a-select-option
            v-for="item in [
              { label: '操作日志', value: 0 },
              { label: '系统日志', value: 1 },
            ]"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-input v-model="form.userName" placeholder="用户" />
      </a-form-model-item>

      <a-form-model-item>
        <a-range-picker v-model="form.date" />
      </a-form-model-item>

      <a-space style="margin-top: 4px">
        <a-button type="primary" @click="query">查询</a-button>
        <a-button @click="reset">重置</a-button>
      </a-space>
    </a-form-model>

    <div style="padding-top: 2vh"></div>

    <a-table
      :data-source="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column title="模块" align="center">
        <template slot-scope="text">
          {{ text.appModule }}
        </template>
      </a-table-column>
      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <span v-if="text.logType === 0">操作日志</span>
          <span v-if="text.logType === 1">系统日志</span>
        </template>
      </a-table-column>

      <a-table-column title="操作">
        <template slot-scope="text">
          {{ text.operation }}
        </template>
      </a-table-column>

      <a-table-column title="用户" align="center">
        <template slot-scope="text">
          {{ text.userName }}
        </template>
      </a-table-column>
      <a-table-column title="时间" align="center">
        <template slot-scope="text">
          {{ text.logDate }}
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <a href="#" @click.prevent="openDetail(text)">详情</a>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      title="日志详情"
      :visible="visible"
      @cancel="visible = false"
      :footer="null"
      width="640px"
    >
      <a-descriptions size="small" :column="2">
        <a-descriptions-item label="描述" :span="2">
          {{ text.businessContent }}
        </a-descriptions-item>

        <a-descriptions-item label="详情" :span="2">
          {{ text.content }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </Card>
</template>

<script>
import { fetchModuleList, fetchList } from "@/api/setting/log";
export default {
  data() {
    return {
      moduleList: [],
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
      text: {},
    };
  },

  mounted() {
    fetchModuleList().then((res) => {
      console.log("res", res);
      if (res && Array.isArray(res.app_module)) {
        this.moduleList = res.app_module;
      }
    });
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize, form } = this;
      const { date } = form;

      let beginTs, endTs;

      if (Array.isArray(date) && date.length === 2) {
        beginTs = date[0].valueOf();
        endTs = date[1].valueOf();
      }
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
        date: undefined,
        beginTs,
        endTs,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = typeof res.totalSize === "number" ? res.totalSize : 0;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },

    openDetail(text) {
      this.visible = true;
      this.text = text;
    },
  },
};
</script>
